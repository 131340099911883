import styled from '@emotion/styled';
import { ScriptableContext } from 'chart.js';
import { Line } from 'react-chartjs-2';

import Color from '~/constants/Color';
import { Text8 } from '~/styles/Texts';
import { Column, Flex, Row, SpaceBetween } from '~/styles/Wrappers';

import { Space } from '../Space';

interface Props {
  labels: string[];
  colors: string[];
  timeline: string[];
  criteria: number[];
  data: number[];
}

const DashedLine = styled.div<{ borderColor: string }>`
  border-style: dotted;
  border-width: 1px;
  border-color: ${({ borderColor }) => borderColor};
`;

const AreaChart = ({ labels, colors, timeline, criteria, data }: Props) => {
  const areaData = {
    labels: (data || []).map((value, index) => index),
    datasets: [
      {
        fill: true,
        data,
        backgroundColor: (context: ScriptableContext<'line'>) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 90);

          colors.map((color, index) => gradient.addColorStop(criteria[index], color));

          return gradient;
        },
      },
    ],
  };

  return (
    <Column style={{ width: 240, height: 110 }}>
      <Row>
        <Column style={{ width: '20%' }}>
          <Text8 color={colors[0]}>{labels[0]}</Text8>
          <Space height={18} />
          <Text8 color={colors[1]}>{labels[1]}</Text8>
          <Space height={22} />
          <Text8 color={colors[2]}>{labels[2]}</Text8>
          <Space height={18} />
          <Text8 color={colors[3]}>{labels[3]}</Text8>
        </Column>

        <Column style={{ position: 'relative', width: '80%' }}>
          <Line
            data={areaData}
            options={{
              plugins: { legend: { display: false } },
              scales: {
                x: {
                  display: false,
                },
                y: {
                  display: false,
                  min: 0,
                  max: 100,
                },
              },
              elements: {
                point: {
                  radius: 0,
                },
                line: {
                  stepped: 'middle',
                  borderWidth: 0,
                },
              },
            }}
          />

          <Column
            style={{ position: 'absolute', width: '99%', height: '100%' }}
            justifyContent="space-around"
          >
            <DashedLine borderColor={colors[0]} />
            <DashedLine borderColor={colors[1]} />
            <DashedLine borderColor={colors[2]} />
          </Column>
        </Column>
      </Row>
      <Space height={4} />

      <Row>
        <Flex style={{ width: '20%' }} />
        <SpaceBetween style={{ width: '80%' }}>
          {timeline.map((time, index) => (
            <Text8 key={index} color={Color.CEFEFEF}>
              {time}
            </Text8>
          ))}
        </SpaceBetween>
      </Row>
    </Column>
  );
};

export default AreaChart;
