import { FocusTimer } from '~/classes/FocusTimer';
import { ItallFocusTimer } from '~/classes/ItallFocusTimer';
import Pagination from '~/classes/Pagination';
import { SearchFocusTimerDto } from '~/classes/dto/FocusTimerDto';
import axios from '~/helper/axios';

const getFocusTimers = async (params: SearchFocusTimerDto): Promise<Pagination<FocusTimer>> => {
  const { data: focusTimers } = await axios<Pagination<FocusTimer>>({
    url: '/focus-timer/focus-timers',
    method: 'get',
    params,
  });

  return new Pagination(focusTimers, FocusTimer);
};

const getItallFocusTimers = async (): Promise<ItallFocusTimer[]> => {
  const { data: itallFocusTimers } = await axios<ItallFocusTimer[]>({
    url: '/focus-timer/ranking/realtime/it-all',
    method: 'get',
  });

  return itallFocusTimers.map((itallFocusTimer) => new ItallFocusTimer(itallFocusTimer));
};

export default { getFocusTimers, getItallFocusTimers };
