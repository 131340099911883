import { FocusTimerState } from './FocusTimer';

export class ItallFocusTimer {
  focusTimerId: string;
  focusTimerState: FocusTimerState;
  time: number;
  totalTime: number;
  withBandTime: number;
  totalWithBandTime: number;
  userId: string;
  avgEfficiencies: number;
  efficiencies: number[];
  email: string;
  nickname: string;
  reportName: string;
  goal: string;
  updatedAt: Date;

  constructor(itallFocusTimer: ItallFocusTimer) {
    this.focusTimerId = itallFocusTimer.focusTimerId;
    this.focusTimerState = itallFocusTimer.focusTimerState;
    this.time = itallFocusTimer.time;
    this.totalTime = itallFocusTimer.totalTime;
    this.withBandTime = itallFocusTimer.withBandTime;
    this.totalWithBandTime = itallFocusTimer.totalWithBandTime;
    this.userId = itallFocusTimer.userId;
    this.avgEfficiencies = itallFocusTimer.avgEfficiencies;
    this.efficiencies = itallFocusTimer.efficiencies;
    this.email = itallFocusTimer.email;
    this.nickname = itallFocusTimer.nickname;
    this.reportName = itallFocusTimer.reportName;
    this.goal = itallFocusTimer.goal;
    this.updatedAt = new Date(itallFocusTimer.updatedAt);
  }
}
