import { Tag } from 'antd';

import { FocusTimerState } from '~/classes/FocusTimer';

enum Label {
  STARTED = '시작', // 시작
  AUTO_SAVED = '자동저장', // 1분 단위 중간 저장
  COMPLETED = '종료', // 종료
}

enum Color {
  STARTED = 'orange', // 시작
  AUTO_SAVED = 'blue', // 1분 단위 중간 저장
  COMPLETED = 'green', // 종료
}

interface Props {
  focusTimerState: FocusTimerState;
  isExpired?: boolean;
}

const FocusTimerStateLabel = ({ focusTimerState, isExpired = false }: Props) => {
  return !isExpired ? (
    <Tag color={Color[focusTimerState]}>{Label[focusTimerState]}</Tag>
  ) : (
    <Tag color="red">만료</Tag>
  );
};

export default FocusTimerStateLabel;
