import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import RefundApi from '~/apis/RefundApi';
import PaginationRequest from '~/classes/PaginationRequest';
import { SearchPointDto } from '~/classes/dto/SearchPointDto';

export const useCreatePoint = () => {
  const { mutateAsync } = useMutation({
    mutationFn: RefundApi.createPoint,
    onError: () => {
      toast.error('포인트를 부여하는데 실패했습니다! 다시 시도해주세요.');
    },
  });

  return mutateAsync;
};

export const useGetPoints = (searchPointDto: SearchPointDto) => {
  const query = useQuery({
    queryKey: ['points', searchPointDto],
    queryFn: () => RefundApi.getPoints(searchPointDto),
  });

  return query;
};

export const useGetRefunds = (paginationRequest: PaginationRequest) => {
  const query = useQuery({
    queryKey: ['refunds', paginationRequest],
    queryFn: () => RefundApi.getRefunds(paginationRequest),
  });

  return query;
};

export const useUpdateRefund = () => {
  const { mutateAsync } = useMutation({
    mutationFn: RefundApi.updateRefund,
    onError: () => {
      toast.error('환급 신청을 처리하는데 실패했습니다! 다시 시도해주세요.');
    },
  });

  return mutateAsync;
};
