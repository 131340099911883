import { Image, Table } from 'antd';
import Search from 'antd/es/input/Search';
import { ColumnsType } from 'antd/es/table';
import { useFormik } from 'formik';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import DefaultProfile from '~/assets/svg/default_profile.svg';
import Pagination from '~/classes/Pagination';
import { Point } from '~/classes/Point';
import HeaderTitleWithSorter from '~/components/HeaderTitleWithSorter';
import { Space } from '~/components/Space';
import useHeaderCell from '~/hooks/useHeaderCell';
import usePaginationRequest from '~/hooks/usePaginationRequest';
import { useGetPoints } from '~/hooks/useRefund';
import { Column, Expanded, SpaceBetween } from '~/styles/Wrappers';

const Points = () => {
  const navigate = useNavigate();

  const { sortingKeywords, onHeaderCell } = useHeaderCell();

  const columns: ColumnsType<Point> = [
    {
      key: 'user',
      title: '프로필',
      dataIndex: 'user',
      render: (user) => (
        <Image
          style={{ borderRadius: 50 }}
          src={user?.picture}
          width={40}
          height={40}
          fallback={DefaultProfile}
        />
      ),
    },
    {
      key: 'user',
      title: '환급 이름',
      dataIndex: 'user',
      render: (user) => <>{user?.refundUserName}</>,
    },
    {
      key: 'user',
      title: '리포트 이름',
      dataIndex: 'user',
      render: (user) => <>{user?.reportName}</>,
    },
    {
      key: 'amount',
      title: (
        <HeaderTitleWithSorter
          title="누적 포인트"
          sortKey="amount"
          sortingKeywords={sortingKeywords}
        />
      ),
      dataIndex: 'amount',
      onHeaderCell: () => onHeaderCell('amount'),
      render: (amount) => <>{amount.toLocaleString()}</>,
    },
    {
      key: 'refundAmount',
      title: (
        <HeaderTitleWithSorter
          title="누적 환급 포인트"
          sortKey="refundAmount"
          sortingKeywords={sortingKeywords}
        />
      ),
      dataIndex: 'refundAmount',
      onHeaderCell: () => onHeaderCell('refundAmount'),
      render: (refundAmount) => <>{refundAmount.toLocaleString()}</>,
    },
    {
      key: 'getRoutineLevel',
      title: '루틴 레벨',
      dataIndex: 'getRoutineLevel',
      render: (_, point) => <>{point.getRoutineLevel()}</>,
    },
    {
      key: 'consecutiveDays',
      title: (
        <HeaderTitleWithSorter
          title="연속 학습일"
          sortKey="consecutiveDays"
          sortingKeywords={sortingKeywords}
        />
      ),
      onHeaderCell: () => onHeaderCell('consecutiveDays'),
      dataIndex: 'consecutiveDays',
    },
    {
      key: 'lastPlayDate',
      title: (
        <HeaderTitleWithSorter
          title="마지막 플레이"
          sortKey="lastPlayDate"
          sortingKeywords={sortingKeywords}
        />
      ),
      onHeaderCell: () => onHeaderCell('lastPlayDate'),
      dataIndex: 'lastPlayDate',
    },
    {
      key: 'last30DaysPlayCount',
      title: '최근 30일 이내 참여 횟수',
      dataIndex: 'last30DaysPlayCount',
    },
    {
      key: 'shieldCount',
      title: (
        <HeaderTitleWithSorter
          title="순공 쉴드"
          sortKey="shieldCount"
          sortingKeywords={sortingKeywords}
        />
      ),
      onHeaderCell: () => onHeaderCell('shieldCount'),
      dataIndex: 'shieldCount',
    },
    {
      key: 'createdAt',
      title: '생성일',
      dataIndex: 'createdAt',
      render: (createdAt) => <>{createdAt.toLocaleDateString()}</>,
    },
    {
      key: 'updatedAt',
      title: '수정일',
      dataIndex: 'updatedAt',
      render: (updatedAt) => <>{updatedAt.toLocaleDateString()}</>,
    },
  ];

  const { values, handleChange, handleSubmit } = useFormik<{ keyword: string }>({
    initialValues: {
      keyword: '',
    },
    validationSchema: Yup.object().shape({
      keyword: Yup.string(),
    }),
    onSubmit: async () => {
      setPage(1);

      refetch();
    },
  });

  const { page, limit } = queryString.parse(location.search);
  const [pagination, setPage, setLimit] = usePaginationRequest(Number(page), Number(limit));
  const { data = new Pagination<Point>(), refetch } = useGetPoints({
    ...pagination,
    sort: sortingKeywords,
    keyword: values.keyword,
  });

  useEffect(() => {
    navigate(`/points?page=${pagination.page}&limit=${pagination.limit}`, { replace: true });
  }, [pagination.page, pagination.limit, navigate]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <Column flex={1}>
      <SpaceBetween>
        <Expanded />
        <Search
          name="keyword"
          style={{ width: 200 }}
          placeholder="검색어를 입력하세요"
          value={values.keyword}
          onChange={handleChange}
          onSearch={() => handleSubmit()}
          allowClear
        />
      </SpaceBetween>
      <Space height={12} />

      <Table
        style={{ overflow: 'scroll' }}
        rowKey={({ _id }) => _id}
        columns={columns}
        dataSource={data.data}
        pagination={{
          current: pagination.page,
          pageSize: pagination.limit,
          showSizeChanger: true,
          total: data.count,
          showTotal: (total) => `Total ${total} items`,
          onChange: (page, pageSize) => {
            setPage(page);
            setLimit(pageSize);
          },
        }}
        scroll={{
          x: true,
        }}
      />
    </Column>
  );
};

export default Points;
