import styled from '@emotion/styled';
import { Image, Tag } from 'antd';
import { Header } from 'antd/es/layout/layout';
import Table, { ColumnsType } from 'antd/es/table';
import { format } from 'date-fns';

import FocusmateRow from '~/assets/svg/foucsmate_row.svg';
import { FocusTimerState } from '~/classes/FocusTimer';
import { ItallFocusTimer } from '~/classes/ItallFocusTimer';
import AreaChart from '~/components/Chart/AreaChart';
import { Space } from '~/components/Space';
import Color from '~/constants/Color';
import { useGetItallFocusTimers } from '~/hooks/useFocusTimer';
import { Text20Bold } from '~/styles/Texts';
import { Center, Column, Row } from '~/styles/Wrappers';
import { secondsToFormattedTime } from '~/utils';

import { COLORS, CRITERIA, LABELS } from '../UserDetail';

const ChartWrapper = styled(Center)`
  width: fit-content;
  height: fit-content;
  background-color: ${Color.C232329};
  border-radius: 12px;
  padding: 16px;
`;

const Itall = () => {
  const columns: ColumnsType<ItallFocusTimer> = [
    {
      key: '',
      title: '랭킹',
      dataIndex: '',
      render: (_, __, index) => <>{index + 1}</>,
    },
    {
      key: 'reportName',
      title: '리포트 이름',
      dataIndex: 'reportName',
    },
    {
      key: 'nickname',
      title: '닉네임',
      dataIndex: 'nickname',
    },
    {
      key: 'goal',
      title: '목표',
      dataIndex: 'goal',
    },
    {
      key: 'withBandTime',
      title: '현재 공부 시간',
      dataIndex: 'withBandTime',
      render: (withBandTime) => <>{secondsToFormattedTime(withBandTime)}</>,
    },
    {
      key: 'totalWithBandTime',
      title: '하루 누적 공부 시간',
      dataIndex: 'totalWithBandTime',
      render: (totalWithBandTime) => <>{secondsToFormattedTime(totalWithBandTime)}</>,
    },
    {
      key: 'focusTimerState',
      title: '진행 상태',
      dataIndex: 'focusTimerState',
      render: (focusTimerState) =>
        focusTimerState === FocusTimerState.COMPLETED ? (
          <Tag color="blue">종료</Tag>
        ) : (
          <Tag color="cyan">진행중</Tag>
        ),
    },
    {
      key: 'avgEfficiencies',
      title: '하루 평균 두뇌가동률',
      dataIndex: 'avgEfficiencies',
      render: (avgEfficiencies) => <>{avgEfficiencies ? `${avgEfficiencies.toFixed(2)}%` : '-'}</>,
    },
    {
      key: 'efficiencies',
      title: '최근 5분 평균 두뇌가동률',
      dataIndex: 'efficiencies',
      render: (efficiencies) => (
        <>
          {efficiencies
            ? `${(efficiencies.reduce((acc, cur) => acc + cur, 0) / efficiencies.length).toFixed(
                2,
              )}%`
            : '-'}
        </>
      ),
    },
    {
      key: 'efficiencies',
      title: '두뇌가동률 차트',
      dataIndex: 'efficiencies',
      render: (efficiencies, focusTimer) => (
        <ChartWrapper>
          <AreaChart
            labels={LABELS}
            colors={COLORS}
            timeline={['약 5분전', format(focusTimer.updatedAt, 'yy. M. d. HH:mm')]}
            criteria={CRITERIA}
            data={efficiencies}
          />
        </ChartWrapper>
      ),
    },
  ];

  const { data } = useGetItallFocusTimers();

  return (
    <Column style={{ minHeight: '100vh' }} flex={1} backgroundColor={Color.CEFEFEF}>
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          maxHeight: '10%',
          backgroundColor: Color.C101011,
        }}
      >
        <Row alignItems="flex-end">
          <Image src={FocusmateRow} width={200} />
          <Space width={24} />

          <Text20Bold color={Color.CFFFFFF}>for 잇올</Text20Bold>
        </Row>
      </Header>
      <Column style={{ padding: 36 }}>
        <Table
          style={{ overflow: 'scroll' }}
          rowKey={({ focusTimerId }) => focusTimerId}
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{
            x: true,
          }}
          onRow={(focusTimer) => {
            const avgEfficiency =
              focusTimer.efficiencies.reduce((acc, cur) => acc + cur, 0) /
              focusTimer.efficiencies.length;

            return {
              style: avgEfficiency < 40 ? { backgroundColor: Color.CFFCCCC } : {},
            };
          }}
        />
      </Column>
    </Column>
  );
};

export default Itall;
